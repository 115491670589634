<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#C7C3E6" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.334 18H8.66732C7.93065 18 7.33398 17.4033 7.33398 16.6667V12C7.33398 11.2633 7.93065 10.6667 8.66732 10.6667H15.334C16.0707 10.6667 16.6673 11.2633 16.6673 12V16.6667C16.6673 17.4033 16.0707 18 15.334 18Z"
      stroke="#292546"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.33398 10.6667V8.66667V8.66667C9.33398 7.194 10.528 6 12.0007 6V6C13.4733 6 14.6673 7.194 14.6673 8.66667V8.66667V10.6667"
      stroke="#292546"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "LockIcon"
};
</script>
