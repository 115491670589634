<template>
  <div v-if="pastGuests && pastGuests.length">
    <div class="grid grid-cols-1 mb-10">
      <h4 class="font-bold text-xl lg:text-2xl text-gray-800 mb-4 lg:mb-8">Past Guests</h4>
      <div class="hidden md:grid md:grid-cols-3 lg:grid-cols-4 gap-6 row-gap-10 md:gap-8">
        <PastGuestProfile :profile="profile" v-for="(profile, index) in pastGuests.slice(0, 4)" :key="index" :fontColor="showColor"/>
      </div>
      <flickity class="block md:hidden" ref="flickity" :options="flickityOptions">
        <div v-for="(profile, index) in pastGuests.slice(0, 4)" :key="index" class="carousel-cell">
          <PastGuestProfile :profile="profile" :key="index" :fontColor="showColor"/>
        </div>
      </flickity>
    </div>
  </div>
</template>

<script>
  import Flickity from 'vue-flickity';
  import PastGuestProfile from '@/components/profile/PastGuestProfile'
  export default {
    name: 'PastGuests',

    data() {
      return {
        flickityOptions: {
          initialIndex: 1,
          prevNextButtons: false,
          pageDots: false,
          wrapAround: true,
          cellAlign: 'left',
          imagesLoaded: true
        }
      }
    },

    components: {
      PastGuestProfile,
      Flickity,
    },

    props: {
      pastGuests: {
        type: Array,
        default: () => {
          return []
        }
      },
      showColor: String
    },

    methods: {
      toggledVip() {
        //
      }
    }
  }
</script>

<style scoped>
.carousel-cell {
  width: 80%;
  @apply mr-5 ;
}
</style>
