<template>
  <div class="flex flex-col itemCard">
    <router-link :to="profileRoute"
      class="rounded-lg pb-full mb-4 relative overflow-hidden w-full">
      <div class="absolute inset-0 bg-center bg-no-repeat bg-cover" style="background-image: url('/images/test_image.png')">
        <template v-if="profile.smallThumbUrl">
          <img :src="profile.smallThumbUrl" class="w-full h-full object-cover" :alt="profile.name">
        </template>
        <template v-else-if="profile.thumbUrl">
          <img :src="profile.thumbUrl" class="w-full h-full object-cover" :alt="profile.name">
        </template>
        <template v-else>
          <img class="w-full h-full object-cover" src="@/assets/images/test_image.png" :alt="profile.name">
        </template>
      </div>
    </router-link>
    <div class="flex justify-between items-center space-x-2">
      <router-link :to="profileRoute" class="leading-5 w-full">
        <h4 class="text-lg mb-1 text-blue-800">{{ profile.name }}</h4>
        <p v-if="profile.type == 'guest'" class="text-sm" :style="textColor">{{ title | truncate(29, "...") }}</p>
        <p v-else class="text-sm" :style="textColor">{{ profile.description | truncate(25, "...") }}</p>
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: "PastGuestProfile",

    props: {
      profile: Object,
      fontColor: {
        type: String,
        default: '#C130EE'
      },
    },

    computed: {
      profileRoute() {
        return {name: 'Guest', params: {guestId: this.profile.slug || this.profile.id}}
      },

      title() {
        return this.profile.title ? this.profile.title : this.profile.bio
      },

      textColor() {
        return `color: ${this.fontColor}`
      }
    }
  };
</script>
