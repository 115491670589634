<template>
  <div>
    <div class="grid grid-cols-1 row-gap-12 md:row-gap-20 mb-12">
      <div v-if="episodes.length">
        <h4 class="font-bold text-xl md:text-2xl text-gray-800 mb-4 md:mb-8">Episodes</h4>
        <div class="hidden md:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-8">
          <Podcast
            :podcast="podcast" v-for="(podcast, index) in episodes" :key="index" :fontColor="showColor"/>
        </div>
        <flickity class="block md:hidden" ref="flickity" :options="flickityOptions">
          <div v-for="(podcast, index) in episodes" :key="index" class="carousel-cell">
            <Podcast :podcast="podcast" :fontColor="showColor"/>
          </div>
        </flickity>
      </div>
      <div v-if="mediaFeatures.length">
        <h4 class="font-bold text-xl lg:text-2xl text-gray-800 mb-4 lg:mb-8">Media Features</h4>
        <div class="hidden md:grid grid-cols-1 md:grid-cols-2 gap-8">
          <Article
            :article="article" v-for="(article, index) in mediaFeatures" :key="index"/>
        </div>
        <flickity class="block md:hidden" ref="flickity" :options="flickityOptions">
          <div v-for="(article, index) in mediaFeatures" :key="index" class="carousel-cell">
            <Article :article="article"/>
          </div>
        </flickity>
      </div>
    </div>
  </div>
</template>

<script>
import Podcast from "@/components/profile/links/Podcast";
import Article from "@/components/profile/links/Article";
import Flickity from 'vue-flickity';

export default {
  name: 'Links',
  props: { links: Object, showColor: String },
  components: {Podcast, Article, Flickity},

  data() {
    return {
      flickityOptions: {
        initialIndex: 1,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        cellAlign: 'left',
        imagesLoaded: true
      }
    }
  },

  computed: {
    episodes() {
      if (! this.links) {
        return []
      }

      return this.links.episodes_parsed
    },

    mediaFeatures() {
      if (! this.links) {
        return []
      }

      return this.links.media_features_parsed
    },
  },
}
</script>
<style scoped>
.carousel-cell {
  width: 80%;
  @apply mr-5 ;
}
</style>
