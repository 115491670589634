<template>
  <div
    v-if="show.apple_podcast_total_ratings || show.apple_podcast_rating"
    class="space-y-8 md:space-y-0 lg:space-y-6 md:grid md:grid-cols-2 md:gap-8 lg:block"
  >
    <div v-if="show.apple_podcast_total_ratings" class="shadow-lg rounded-xl bg-white overflow-hidden">
      <div class="px-6 py-2 bg-gray-300 flex items-center space-x-2">
        <img src="/images/apple-podcasts.png" class="h-5 w-5" />
        <span class="text-gray-500">Apple Podcasts</span>
      </div>
      <div class="px-6 py-4 flex flex-col font-sans">
        <span class="text-blue-800">
          Total Ratings
        </span>
        <span class="mt-2 text-blue-800 font-black text-4.5xl leading-none">
          {{ show.apple_podcast_total_ratings }}
        </span>
      </div>
    </div>
    <div v-if="show.apple_podcast_rating" class="shadow-lg rounded-xl bg-white overflow-hidden">
      <div class="px-6 py-2 bg-gray-300 flex items-center space-x-2">
        <img src="/images/apple-podcasts.png" class="h-5 w-5" />
        <span class="text-gray-500">Apple Podcasts</span>
      </div>
      <div class="px-6 py-4 flex flex-col font-sans">
        <span class="text-blue-800">
          Average Rating
        </span>
        <span class="mt-2 text-blue-800 text-4.5xl leading-none">
          <span class="font-black">{{ show.apple_podcast_rating }}</span>/5
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      show: Object,
    },

    components: {
      //
    }
  }
</script>
